import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecificPageValues,
  SiteThunkType,
  updSpecificPageValues,
} from "../../store/actions/siteActions";
import { AnyAction } from "redux";
import { AppStateType } from "../../store";
import { ISpecificPageValue } from "../../types";
import { Button, Form, Input, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
const { Title } = Typography;

interface IProps {
  siteId: string;
}

interface IVal {
  [param: string]: { [param: string]: ISpecificPageValue[] };
}

type FieldType = {
  [param: string]: string;
};

export const SpecificPageValues = ({ siteId }: IProps) => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const [values, setValues] = useState<IVal | null>(null);
  const [valuesToUpdate, setValuesToUpdate] = useState<{
    [param: string]: string;
  }>({});
  const specificPageValues = useSelector(
    (state: AppStateType) => state.site.specificPageValues
  );
  const isUpdSpecificPageValuesInProgress = useSelector(
    (state: AppStateType) => state.site.isUpdSpecificPageValuesInProgress
  );
  const isLoadingSpecificPageValuesInProgress = useSelector(
    (state: AppStateType) => state.site.isLoadingSpecificPageValuesInProgress
  );

  useEffect(() => {
    if (siteId) {
      dispatch(getSpecificPageValues(siteId));
    }
  }, [siteId]);

  useEffect(() => {
    let vals = {} as IVal;
    specificPageValues.forEach((spv) => {
      if (spv.promtForSpecificPage.page) {
        vals[spv.promtForSpecificPage.page] = {
          ...vals[spv.promtForSpecificPage.page],
          [spv.itemName]: [
            ...(vals[spv.promtForSpecificPage.page]?.[spv.itemName]
              ? vals[spv.promtForSpecificPage.page]?.[spv.itemName]
              : []),
            spv,
          ],
        };
      }
    });
    setValues(vals);
  }, [specificPageValues]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (values && form) {
      specificPageValues.forEach((spv) => {
        form.setFieldValue(
          `${spv.itemName}${spv.promtForSpecificPage.tag.fieldInDb}`,
          spv.itemValue
        );
      });
    }
  }, [specificPageValues, values, form]);

  useEffect(() => {
    console.log("valuesToUpdate", valuesToUpdate);
  }, [valuesToUpdate]);

  return (
    <div
      style={{
        borderLeft: "1px solid",
        paddingLeft: 20,
        width: 500,
        paddingRight: 20,
      }}
    >
      <Form
        style={{
          minWidth: 400,
          borderBottom: "1px solid",
          paddingBottom: 20,
        }}
        form={form}
        layout="vertical"
      >
        {values &&
          Object.keys(values).map((val) => (
            <>
              {/* <Title style={{ marginBottom: 20, color: "red" }} level={3}>
              {val.toLocaleUpperCase()}
            </Title> */}
              {Object.keys(values[val]).map((item) => {
                return (
                  <>
                    <Title style={{ marginBottom: 20, color: "red" }} level={3}>
                      {val.toLocaleUpperCase()} PAGE ({item})
                    </Title>

                    {values[val][item].map((t) => (
                      <Form.Item<FieldType>
                        key={t.promtForSpecificPage.tag.fieldInDb}
                        label={
                          <Title style={{ marginTop: 0 }} level={4}>
                            {t.promtForSpecificPage.tag.fieldInDb}
                          </Title>
                        }
                        name={`${item}${t.promtForSpecificPage.tag.fieldInDb}`}
                      >
                        <TextArea
                          disabled={isLoadingSpecificPageValuesInProgress}
                          onChange={(val) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              [t._id]: val.target.value,
                            })
                          }
                          rows={2}
                          size="middle"
                          placeholder={`Enter ${t.promtForSpecificPage.tag.fieldInDb}`}
                        />
                      </Form.Item>
                    ))}

                    <Button
                      style={{ width: 150 }}
                      onClick={() => {
                        dispatch(updSpecificPageValues(siteId, valuesToUpdate));
                        setValuesToUpdate({});
                      }}
                      loading={isUpdSpecificPageValuesInProgress}
                      type="primary"
                      size="middle"
                      disabled={isLoadingSpecificPageValuesInProgress}
                    >
                      Save
                    </Button>
                  </>
                );
              })}
            </>
          ))}
      </Form>
    </div>
  );
};
