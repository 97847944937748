import {
  Button,
  message,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { mainUrl } from "../helpers/constants";
import { AppStateType } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getBeautifullDate } from "../helpers/helpers";
import {
  deleteImage,
  getImages,
  ImageThunkType,
} from "../store/actions/imageActions";
import { AnyAction } from "redux";
import {
  getAllApplianceBrands,
  getAllAppliances,
} from "../store/actions/applianceActions";
import { LoadingOutlined } from "@ant-design/icons";
const { Title } = Typography;

export const Images = () => {
  const dispatch = useDispatch() as Dispatch<ImageThunkType | AnyAction>;
  const [activeItemName, setActiveItemName] = useState("");
  const [uploadProps, setUploadProps] = useState<UploadProps | null>(null);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [formattedAppliances, setFormattedAppliances] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [formattedBrands, setFormattedBrands] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [currentSearchParams, setCurrentSearchParams] = useState<{
    [param: string]: string;
  }>();
  let [searchParams, setSearchParams] = useSearchParams();
  const images = useSelector((state: AppStateType) => state.image.images);
  const appliances = useSelector(
    (state: AppStateType) => state.appliance.appliances
  );
  const applianceBrands = useSelector(
    (state: AppStateType) => state.appliance.applianceBrands
  );
  const imagesTotal = useSelector(
    (state: AppStateType) => state.image.imagesTotal
  );
  const isImagesLoadingInProgress = useSelector(
    (state: AppStateType) => state.image.isImagesLoadingInProgress
  );
  const isImageDeletingInProgress = useSelector(
    (state: AppStateType) => state.image.isImageDeletingInProgress
  );

  useEffect(() => {
    setCurrentSearchParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      getImages(
        currentSearchParams?.page ? Number(currentSearchParams?.page) : 1,
        currentSearchParams?.category || "all"
      )
    );
  }, [currentSearchParams?.page, currentSearchParams?.category]);

  useEffect(() => {
    setColumns([
      {
        title: (
          <div>
            <div>Category</div>
            <div>
              <Select
                style={{ width: 170 }}
                onSelect={(val) =>
                  setSearchParams({
                    ...currentSearchParams,
                    category: `${val}`,
                  })
                }
                //style={{ width: 200, marginBottom: 20 }}
                options={formattedAppliances}
              />
            </div>
          </div>
        ),
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Image",
        dataIndex: "url",
        key: "url",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ]);
  }, []);

  useEffect(() => {
    let props: UploadProps | null = null;
    if (activeItemName) {
      props = {
        name: "file",
        action: `${mainUrl}api/v1/image/upload/${activeItemName}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        beforeUpload: (file) => {
          const isImage =
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png";
          if (!isImage) {
            message.error(`${file.name} is not an image (jpg, png)`);
          }
          return isImage || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
          console.log(info.fileList);
          if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            dispatch(
              getImages(
                currentSearchParams?.page
                  ? Number(currentSearchParams?.page)
                  : 1,
                currentSearchParams?.category || "all"
              )
            );
          } else if (info.file.status === "error") {
            if (info.file?.response?.message) {
              message.error(info.file?.response?.message);
            } else {
              message.error(`${info.file.name} file upload failed.`);
            }
          }
        },
      };
    }
    setUploadProps(props);
  }, [activeItemName]);

  useEffect(() => {
    const ds = [] as any[];
    images.forEach((t) => {
      ds.push({
        key: t._id,
        url: (
          <img
            style={{ width: 200 }}
            src={`${mainUrl}${t.url.replace("/data/uploads/", "")}`}
          />
        ),
        action: (
          <Popconfirm
            title="Delete"
            description="Are you sure to delete?"
            onConfirm={() =>
              dispatch(
                deleteImage(
                  t._id,
                  Number(currentSearchParams?.page) || 1,
                  currentSearchParams?.category || "all"
                )
              )
            }
          >
            <Button
              style={{ color: "red" }}
              loading={isImageDeletingInProgress}
              type="link"
              size="middle"
            >
              Delete
            </Button>
          </Popconfirm>
        ),
        category: t.category,
        created: getBeautifullDate(t.createdAt),
        updated: getBeautifullDate(t.updatedAt),
      });
    });
    setDataSource(ds);
  }, [images]);

  const onPageChange = (current: number) => {
    setSearchParams({ ...currentSearchParams, page: `${current}` });
  };

  useEffect(() => {
    dispatch(getAllAppliances());
    dispatch(getAllApplianceBrands());
  }, []);

  useEffect(() => {
    const formatted = [{ value: "Index", label: "Index" }] as {
      value: string;
      label: string;
    }[];
    appliances.forEach((appliance) => {
      formatted.push({
        value: appliance.name,
        label: appliance.name,
      });
    });
    setFormattedAppliances(formatted);
  }, [appliances]);

  useEffect(() => {
    const brands = [] as {
      value: string;
      label: string;
    }[];
    applianceBrands.forEach((b) => {
      if (!brands.some((sb) => sb.value === b.brand.name)) {
        brands.push({
          value: b.brand.name,
          label: b.brand.name,
        });
      }
    });
    setFormattedBrands(brands);
  }, [applianceBrands]);

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 20,
        flexDirection: "column",
      }}
    >
      {isImagesLoadingInProgress ? (
        <LoadingOutlined />
      ) : (
        <>
          <Title style={{ marginTop: 0 }} level={4}>
            Select image category
          </Title>
          <Select
            onSelect={(val) => setActiveItemName(val)}
            style={{ width: 200, marginBottom: 20 }}
            options={[...formattedAppliances, ...formattedBrands]}
          />
          {activeItemName && (
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Upload image</Button>
            </Upload>
          )}

          <Table
            style={{ marginTop: 20 }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
          <Pagination
            showTotal={(total) => `Total ${total} items`}
            pageSize={50}
            defaultCurrent={1}
            total={imagesTotal}
            onChange={onPageChange}
          />
        </>
      )}
    </div>
  );
};
