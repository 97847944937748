import {
  Breadcrumb,
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  FormProps,
  Input,
  Select,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import React, { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../store";
import {
  createSpecificPagePromt,
  deleteSpecificPagePromt,
  getAllTags,
  getOneTag,
  loadSpecificPagePromts,
  TagThunkType,
  updateTag,
} from "../store/actions/tagActions";
import { ITag } from "../types";

type FieldType = {
  tagName: string;
  fieldInDb: string;
  aiPromt?: string;
  imageSize?: string;
  appliancePageAiPromt?: string;
  brandPageAiPromt?: string;
};

export const Tag = () => {
  const { Link } = Typography;
  const [form] = Form.useForm();
  const [isGenerateByAi, setIsGenerateByAi] = useState(false);
  const [isDepends, setIsDepends] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [tagsSelectItems, setTagsSelectItems] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedDependTag, setSelectedDependTag] = useState("");
  const [specificPageUpdateField, setSpecificPageUpdateField] = useState<{
    [param: string]: string;
  }>({});
  const [isAddPromtForSpecificPage, setIsAddPromtForSpecificPage] =
    useState(false);
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const [selectedPage, setSelectedPage] = useState<string>("");
  const [promtForSpecificPage, setPromtForSpecificPage] = useState<string>("");
  const { TextArea } = Input;
  const dispatch = useDispatch() as Dispatch<TagThunkType | AnyAction>;
  let { id } = useParams();
  const tag = useSelector((state: AppStateType) => state.tag.tag);
  const allTags = useSelector((state: AppStateType) => state.tag.allTags);

  const isTagsLoadingInProgress = useSelector(
    (state: AppStateType) => state.tag.isTagsLoadingInProgress
  );
  const isTagUpdateInProgress = useSelector(
    (state: AppStateType) => state.tag.isTagUpdateInProgress
  );
  const isCreatingSpecificPagePromtInProgress = useSelector(
    (state: AppStateType) => state.tag.isCreatingSpecificPagePromtInProgress
  );
  const specificPagePromts = useSelector(
    (state: AppStateType) => state.tag.specificPagePromts
  );
  const isLoadingfSpecificPagePromtInProgress = useSelector(
    (state: AppStateType) => state.tag.isLoadingfSpecificPagePromtInProgress
  );
  const isDeletingSpecificPagePromtInProgress = useSelector(
    (state: AppStateType) => state.tag.isDeletingSpecificPagePromtInProgress
  );
  useEffect(() => {
    if (id) {
      dispatch(getOneTag(id));
      dispatch(loadSpecificPagePromts(id));
    }
  }, [id]);

  useEffect(() => {
    console.log("tag", tag);
    if (tag?._id) {
      form.setFieldValue("tagName", tag.tagName);
      form.setFieldValue("fieldInDb", tag.fieldInDb);
      form.setFieldValue("aiPromt", tag.aiPromt);
      form.setFieldValue("imageSize", tag.imageSize);
      if (tag.isGenerateByAi) {
        setIsGenerateByAi(true);
      }
      if (tag.isImage) {
        setIsImage(true);
      }
      if (tag.isDepends) {
        setIsDepends(true);
      }
      if (tag.dependsTags) {
        setTagsArray(tag.dependsTags);
      }
    }
  }, [tag?._id]);

  useEffect(() => {
    specificPagePromts.forEach((spp) => {
      form.setFieldValue(`${spp.page}PageAiPromt`, spp.promt);
    });
  }, [specificPagePromts]);

  useEffect(() => {
    if (isDepends && allTags.length === 0) {
      dispatch(getAllTags());
    }
    if (allTags.length > 0) {
      let tagsFormatted = [] as { label: string; value: string }[];
      allTags.forEach((t) => {
        tagsFormatted.push({ label: t.tagName, value: t._id });
      });
      setTagsSelectItems(tagsFormatted);
    }
  }, [isDepends, allTags]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (tag?._id) {
      dispatch(
        updateTag(
          tag?._id,
          {
            ...values,
            isGenerateByAi: isGenerateByAi,
            isImage: isImage,
            isDepends: isDepends,
            dependsTags: tagsArray,
          } as ITag,
          specificPageUpdateField
        )
      );
    }
  };

  useEffect(() => {
    console.log("specificPageUpdateField", specificPageUpdateField);
  }, [specificPageUpdateField]);

  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsGenerateByAi(e.target.checked);
  };

  const onChangeIsImage: CheckboxProps["onChange"] = (e) => {
    setIsImage(e.target.checked);
  };

  const onChangeIsDepends: CheckboxProps["onChange"] = (e) => {
    setIsDepends(e.target.checked);
  };

  const onChangeDepend = (value: string) => {
    console.log(`selected ${value}`);

    setSelectedDependTag(value);
  };

  useEffect(() => {
    console.log("specificPagePromts", specificPagePromts);
  }, [specificPagePromts]);

  const createSpecPromt = (
    promtForSpecificPage: string,
    selectedPage: string,
    id: string
  ) => {
    dispatch(createSpecificPagePromt(promtForSpecificPage, selectedPage, id));
    setIsAddPromtForSpecificPage(false);
  };

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 10,
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Breadcrumb
          items={[
            {
              href: "/tags",
              title: (
                <>
                  <ArrowLeftOutlined />
                  <span>Tags</span>
                </>
              ),
            },
            {
              title: `Tag ${id}`,
            },
          ]}
        />
        {isTagsLoadingInProgress && <LoadingOutlined />}
      </div>

      <Form
        style={{
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
        }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item<FieldType>
          label="Tag name (e.g. company-name)"
          name="tagName"
          style={{ marginRight: 10 }}
        >
          <Input
            style={{ width: 250 }}
            size="middle"
            placeholder="Enter tag name"
          />
        </Form.Item>
        <Form.Item<FieldType>
          label="Field name in site database"
          name="fieldInDb"
          style={{ marginRight: 10 }}
        >
          <Input
            style={{ width: 250 }}
            size="middle"
            placeholder="Enter field name"
          />
        </Form.Item>

        <Checkbox
          style={{ marginBottom: 10 }}
          onChange={onChange}
          checked={isGenerateByAi}
        >
          Generate by AI
        </Checkbox>
        {isGenerateByAi && (
          <>
            <Form.Item<FieldType> label="Promt for AI (default)" name="aiPromt">
              <TextArea rows={4} placeholder="Enter promt for AI generation" />
            </Form.Item>

            {!isAddPromtForSpecificPage && (
              <Link
                style={{ marginBottom: 20 }}
                onClick={() => setIsAddPromtForSpecificPage(true)}
              >
                Create promt for specific page
              </Link>
            )}

            {isAddPromtForSpecificPage && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Form.Item<FieldType>
                  label="Select page"
                  style={{ width: "100%", margin: 0, padding: 0 }}
                >
                  <Select
                    onChange={(val) => setSelectedPage(val)}
                    style={{ width: 200, marginBottom: 20 }}
                    showSearch
                    placeholder="Select page"
                    optionFilterProp="label"
                    options={[
                      {
                        label: "Appliance page",
                        value: "appliance",
                      },
                      {
                        label: "Brand page",
                        value: "brand",
                      },
                    ].filter(
                      (o) =>
                        !specificPagePromts.some((sp) => sp.page === o.value)
                    )}
                  />
                </Form.Item>

                <Form.Item<FieldType>
                  label="Enter promt for page"
                  style={{ width: "100%", margin: 0, padding: 0 }}
                >
                  <TextArea
                    onChange={(val) =>
                      setPromtForSpecificPage(val.target.value)
                    }
                    disabled={!selectedPage}
                    rows={4}
                    placeholder="Enter promt for AI generation"
                  />
                </Form.Item>

                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <Link
                    disabled={
                      !selectedPage ||
                      !promtForSpecificPage ||
                      isCreatingSpecificPagePromtInProgress
                    }
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                      id
                        ? createSpecPromt(
                            promtForSpecificPage,
                            selectedPage,
                            id
                          )
                        : null
                    }
                  >
                    {!isCreatingSpecificPagePromtInProgress
                      ? "Add"
                      : "Adding..."}
                  </Link>
                  <Link
                    style={{ marginLeft: 10, color: "red" }}
                    onClick={() => setIsAddPromtForSpecificPage(false)}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            )}

            {isLoadingfSpecificPagePromtInProgress && <div>Loading...</div>}
            {specificPagePromts.map((spp) => (
              <div
                style={{
                  marginBottom: 20,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form.Item<FieldType>
                  key={spp._id}
                  name={`${spp.page}PageAiPromt`}
                  label={`Specific promt for ${spp.page.toLocaleUpperCase()} page`}
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <TextArea
                    onChange={(
                      val //null
                    ) =>
                      setSpecificPageUpdateField({
                        ...specificPageUpdateField,
                        [spp._id]: val.target.value,
                      })
                    }
                    rows={4}
                    placeholder={`Enter AI promt for ${spp.page} page`}
                  />
                </Form.Item>
                <Link
                  onClick={() =>
                    id ? dispatch(deleteSpecificPagePromt(spp._id, id)) : null
                  }
                  disabled={isDeletingSpecificPagePromtInProgress}
                  style={{
                    color: "red",
                    marginTop: 0,
                    paddingTop: 0,
                    width: 100,
                    marginLeft: 10,
                  }}
                >
                  Delete
                </Link>
              </div>
            ))}

            <Checkbox
              style={{ marginBottom: 20 }}
              onChange={onChangeIsImage}
              checked={isImage}
            >
              <FileImageOutlined /> Image
            </Checkbox>

            {isImage && (
              <Form.Item<FieldType>
                label="Size (default 500px)"
                name="imageSize"
                style={{ marginRight: 10 }}
              >
                <Select
                  style={{ width: 350 }}
                  showSearch
                  placeholder="500px"
                  optionFilterProp="label"
                  options={[
                    {
                      label: "750px",
                      value: 750,
                    },
                    {
                      label: "500px",
                      value: 500,
                    },
                    {
                      label: "250px",
                      value: 250,
                    },
                  ]}
                />
              </Form.Item>
            )}
          </>
        )}

        <Checkbox
          style={{ marginBottom: 10 }}
          onChange={onChangeIsDepends}
          checked={isDepends}
        >
          Depends on other tags
        </Checkbox>

        {isDepends &&
          (isTagsLoadingInProgress ? (
            <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
          ) : (
            <>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Select
                  style={{ width: 350 }}
                  showSearch
                  placeholder="Select a tag"
                  optionFilterProp="label"
                  onChange={onChangeDepend}
                  options={tagsSelectItems}
                />
                <Link
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    setTagsArray([
                      ...tagsArray.filter((t) => t !== selectedDependTag),
                      selectedDependTag,
                    ])
                  }
                >
                  Add
                </Link>
              </div>
              <div style={{ marginBottom: 10, marginTop: 10 }}>
                {tagsArray.map((tag) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {allTags?.find((t) => t._id === tag)?.tagName}
                    </div>
                    <Button
                      onClick={() =>
                        setTagsArray([...tagsArray.filter((t) => t !== tag)])
                      }
                      style={{ width: 150, color: "red" }}
                      type="link"
                      size="middle"
                    >
                      Delete
                    </Button>
                  </div>
                ))}
              </div>
            </>
          ))}

        <Button
          style={{ width: 150, marginTop: 4 }}
          htmlType="submit"
          loading={isTagUpdateInProgress}
          type="primary"
          size="middle"
          disabled={false}
        >
          Save
        </Button>
      </Form>
    </div>
  );
};
